const { default: server } = require("./server");

export const getSettingsApi = async (slug) => {
  const response = await server().get(`/settings/${slug}`);
  return response.data;
};

export const editSettingsApi = async (data) => {
  const response = await server().put(`/settings/siteInfo`, data);

  return response.data;
};

export const editStoreBasicSettingsApi = async ({ data, slug }) => {
  const response = await server().put(`/settings/basicSettings/${slug}`, data);

  return response.data;
};

export const editStoreSlugSettingsApi = async ({ data, slug }) => {
  const response = await server().put(`/settings/${slug}`, data);
  return response.data;
};

export const editStoreLangsApi = async ({ data }) => {
  const response = await server().put(`/settings/languageSettings`, data);

  return response.data;
};
export const editStoreDefaultLanguageApi = async ({ languageId }) => {
  const response = await server().put(
    `/settings/languageSettings/isDefault/${languageId}`
  );

  return response.data;
};
export const editStoreDefaultCurrencyApi = async ({ currencyId }) => {
  const response = await server().put(
    `/settings/currencySettings/isDefault/${currencyId}`
  );

  return response.data;
};

export const editStoreCurrenciesApi = async ({ data }) => {
  const response = await server().put(`/settings/currencySettings`, data);

  return response.data;
};

export const getBankAccountApi = async () => {
  const response = await server().get(`/bankAccounts`);

  return response.data;
};

export const addBankAccountApi = async ({ data }) => {
  const response = await server().post(`/bankAccounts`, data);

  return response.data;
};

export const editBankAccountApi = async ({ data, id }) => {
  const response = await server().put(`/bankAccounts/${id}`, data);

  return response.data;
};

export const setDefaultBankAccountApi = async (id) => {
  const response = await server().post(`/bankAccounts/setDefault/${id}`);

  return response.data;
};

export const deleteBankAccountApi = async (id) => {
  const response = await server().delete(`/bankAccounts/${id}`);

  return response.data;
};
