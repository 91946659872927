import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import shopIcon from "../../assets/svgs/shop.svg";
import Table from "./Table";
const Index = () => {
  const { stores, metadata } = useSelector((state) => state.stores);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="إضافة متجر"
          cardTxt="المتاجر"
          icon={shopIcon}
          url="/stores/addstore"
        />
        <Table data={stores} metaData={metadata} />
      </div>
    </>
  );
};

export default Index;
