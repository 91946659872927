import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  editStoreDefaultCurrency,
  editStoreSlugSetting,
  getSettings,
} from "store/actions";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { getId } from "helpers/functions";
import BreadCrumb from "components/shared/BreadCrumb";
import { ReactComponent as DollarIcon } from "assets/svgs/product/dollar.svg";

const StoreCurrencies = () => {
  const { locale, formatMessage } = useIntl();
  const navigate = useNavigate();
  const [isDefault, setIsDefault] = useState("");

  const { settings, dataSaveLoading, loading } = useSelector(
    (state) => state.settings || {}
  );
  const { currencies = [] } = settings;

  const dispatch = useDispatch();
  const { register, handleSubmit, reset, watch, setValue } = useForm();

  useEffect(() => {
    dispatch(getSettings("currencySettings"));
  }, [dispatch]);

  useEffect(() => {
    if (currencies?.length > 0) {
      const activeCurrencies = currencies.filter(
        (currency) => currency.isActive
      );
      const currentActiveCurrencies = watch("currencies") || [];
      const newActiveCurrencies = activeCurrencies.map(
        (currency) => currency._id
      );

      if (
        JSON.stringify(currentActiveCurrencies) !==
        JSON.stringify(newActiveCurrencies)
      ) {
        reset({
          currencies: newActiveCurrencies,
        });
      }

      const activecurrencyIds = activeCurrencies.map(
        (currency) => currency._id
      );
      if (activecurrencyIds.length === 1) {
        const defaultcurrency = activecurrencyIds[0];

        if (isDefault !== defaultcurrency) {
          setIsDefault(defaultcurrency);
          dispatch(editStoreDefaultCurrency({ currencyId: defaultcurrency }));
        }
      } else {
        const defaultCurrency =
          currencies.find((currency) => currency.isDefault)?._id || "";
        setIsDefault(defaultCurrency);
      }
    }
  }, [currencies, isDefault, reset, dispatch, watch]);
  const renderCurrenciesCheckBoxes = currencies.map((item, index) => (
    <Col xs="12" lg="4" md="6" key={index}>
      <div className="lang card">
        <p title={item.name[locale]}>{item.name[locale]}</p>
        <label>
          <input
            type="radio"
            onChange={({ target }) => {
              if (target.checked && item.isActive) {
                setIsDefault(item._id);
                dispatch(
                  editStoreDefaultCurrency({
                    currencyId: item._id,
                  })
                );
              }
            }}
            checked={isDefault === getId(item)}
            value={getId(item)}
            title={formatMessage({ id: "isDefault" })}
          />
          <p className="active">{formatMessage({ id: "isDefault" })}</p>
          <p
            className="inactive"
            style={{
              cursor: `${item.isActive ? "pointer" : "not-allowed"}`,
            }}
          >
            {formatMessage({ id: "setAsDefault" })}
          </p>
        </label>
        <label
          className="switcher"
          title={formatMessage({ id: "addCurrencyToStore" })}
        >
          <input
            type="checkbox"
            className="switcher-input"
            defaultChecked={false}
            {...register("currencies")}
            onChange={({ target }) => {
              const selectedCurrencies = watch("currencies") || [];
              if (target.checked) {
                setValue("currencies", [...selectedCurrencies, item._id]);
              } else {
                setValue(
                  "currencies",
                  selectedCurrencies.filter((id) => id !== item._id)
                );
              }
            }}
            value={item._id}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </label>
      </div>
    </Col>
  ));

  const onSubmit = (data) => {
    data.currencies =
      data.currencies?.map((currency) => ({
        currency,
        isDefault: isDefault === currency,
      })) || [];
    dispatch(
      editStoreSlugSetting({ data, slug: "currencySettings", navigate })
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="acc-form store-langs">
      {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
      <BreadCrumb
        pageName={formatMessage({ id: "storeCurrencies" })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <div className="card flex-row justify-content-start align-items-center gap-2 card-head">
        <DollarIcon />
        <h4 className="m-0">
          <FormattedMessage id="storeCurrencies" />
        </h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Col xl={10} md={12}>
          <Row className="langs">{renderCurrenciesCheckBoxes}</Row>
          <Row>
            <Col lg={4} xs={12}>
              <div className="form-group">
                <button type="submit" className="btn btn-blue">
                  <FormattedMessage id="saveChanges" />
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </form>
    </div>
  );
};

export default StoreCurrencies;
