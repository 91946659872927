import React, { useMemo, useState } from "react";
import { Accordion, Form } from "react-bootstrap";

import filterIcon from "assets/svgs/filter.svg";
import closeIcon from "assets/svgs/close.svg";
import statusIcon from "assets/svgs/order-status.svg";

import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getId } from "helpers/functions";

const CouponsFilterSidebar = ({ filtersObj, href = "/customers" }) => {
  const { locale } = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [tempFilters, setTempFilters] = useState(() => {
    const initialFilters = {};
    Object.keys(filtersObj).forEach((filterName) => {
      const value = searchParams.get(filterName);
      if (value) {
        try {
          initialFilters[filterName] = JSON.parse(decodeURI(value));
        } catch {
          initialFilters[filterName] = value;
        }
      }
    });
    return initialFilters;
  });

  const updateTempFilters = (name, value, isArray = false) => {
    setTempFilters((prevFilters) => {
      let updatedFilters = { ...prevFilters };

      if (isArray) {
        const currentValues = updatedFilters[name] || [];
        if (currentValues.includes(value)) {
          updatedFilters[name] = currentValues.filter((v) => v !== value);
        } else {
          updatedFilters[name] = [...currentValues, value];
        }

        if (updatedFilters[name].length === 0) {
          delete updatedFilters[name];
        }
      } else {
        if (!value) {
          delete updatedFilters[name];
        } else {
          updatedFilters[name] = value;
        }
      }

      return updatedFilters;
    });
  };

  const handleFilterSubmit = () => {
    const params = new URLSearchParams();
    Object.entries(tempFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        params.set(key, JSON.stringify(value));
      } else if (!Array.isArray(value)) {
        params.set(key, value);
      }
    });

    const paramsStr = params.toString();
    navigate(`${href}${paramsStr ? `?${paramsStr}` : ""}`, { replace: true });
    handleclose();
  };

  const handleReset = () => {
    setTempFilters({});
    navigate(href, { replace: true });
    handleclose();
  };

  const handleclose = () => {
    document.querySelector(".ordersFilterSidebar")?.classList.toggle("active");
    document.querySelector(".overlay-g")?.classList.toggle("active");
  };

  const renderFilters = useMemo(
    () =>
      Object.keys(filtersObj).map((filterName, index) => {
        const filterObj = filtersObj?.[filterName];
        let data = filterObj?.data;

        if (filterName === "areas") {
          data = data?.filter(
            (a) => getId(a?.city) === getId(searchParams.get("city"))
          );
        }
        if (filterName === "city") {
          data = data?.filter(
            (a) => getId(a?.country) === getId(searchParams.get("country"))
          );
        }

        if (filterObj?.type === "none") return null;

        if (filterObj?.type === "dateRange") {
          return (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>
                <span>
                  <img src={statusIcon} alt="" />
                  <FormattedMessage id={filterName} />
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <label>
                      <span>
                        <FormattedMessage id="from" />
                      </span>
                      <Form.Control
                        type="date"
                        defaultValue={tempFilters?.from || ""}
                        onChange={({ target: { value } }) =>
                          updateTempFilters("from", value)
                        }
                      />
                    </label>
                  </li>
                  <li>
                    <label>
                      <span>
                        <FormattedMessage id="to" />
                      </span>
                      <Form.Control
                        type="date"
                        defaultValue={tempFilters?.to || ""}
                        onChange={({ target: { value } }) =>
                          updateTempFilters("to", value)
                        }
                      />
                    </label>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          );
        }

        if (data?.length === 0) return null;

        return (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <span>
                <img src={statusIcon} alt="" />
                <FormattedMessage id={filterName} />
              </span>
            </Accordion.Header>
            <Accordion.Body className={filterObj?.className || ""}>
              <ul>
                {data?.map((filter, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type={filterObj?.type}
                        name={filterName}
                        value={getId(filter)}
                        onClick={({ target: { value } }) =>
                          updateTempFilters(
                            filterName,
                            value,
                            filterObj?.type === "checkbox"
                          )
                        }
                        checked={
                          Array.isArray(tempFilters[filterName])
                            ? tempFilters[filterName]?.includes(getId(filter))
                            : tempFilters[filterName] === getId(filter)
                        }
                      />
                      <span>
                        {filter?.name?.[locale] || (
                          <FormattedMessage id={filter} />
                        )}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        );
      }),
    [filtersObj, tempFilters, locale]
  );

  return (
    <div className="ordersFilterSidebar">
      <div className="sidebarHead">
        <span>
          <img src={filterIcon} alt="" />
          فرز حسب
        </span>
        <button className="closeSidebar" onClick={handleclose}>
          <img src={closeIcon} alt="" />
        </button>
      </div>
      <div className="sidebarBody">
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          {renderFilters}
        </Accordion>
      </div>
      <div className="sidebarFooter">
        <button className="btn btn-blue" onClick={handleFilterSubmit}>
          حفظ التغييرات
        </button>
        <button className="btn btn-red" onClick={handleReset}>
          إلغاء
        </button>
      </div>
    </div>
  );
};

export default CouponsFilterSidebar;
