import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
    activateCouponApi,
    addCouponApi,
    deleteCouponApi,
    editCouponApi,
    getCouponsApi,
    getSingleCouponApi,
} from "api/coupons";

// Login Redux States
import {
    ACTIVATE_COUPON,
    ADD_COUPON,
    DELETE_COUPON,
    EDIT_COUPON,
    GET_COUPONS,
    GET_SINGLE_COUPON,
} from "./actionTypes";
import {
    activateCouponFailure,
    activateCouponSuccess,
    addCouponFailure,
    addCouponSuccess,
    deleteCouponFailure,
    deleteCouponSuccess,
    editCouponFailure,
    editCouponSuccess,
    getCouponsFailure,
    getCouponsSuccess,
    getSingleCouponFailure,
    getSingleCouponsuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { toastSuccessMessage } from "helpers/toaster/toastSuccessMessage";

function* getCoupons({ payload }) {
    try {
        const { data } = yield call(getCouponsApi, payload);
        console.log("from saga", data);
        yield put(getCouponsSuccess(data));
    } catch (error) {
        toastErrorMessage({ error: error?.response?.data });
        console.log(error);
        yield put(getCouponsFailure(error?.response?.data?.errors?.[0]?.msg));
    }
}

function* addCoupon({ payload }) {
    try {
        const { data } = yield call(addCouponApi, payload.data);
        yield put(addCouponSuccess(data));
        if (payload.navigate) yield payload.navigate("/coupons");
        if (payload?.callBack) payload?.callBack();
    } catch (error) {
        toastErrorMessage({ error: error?.response?.data, autoClose: 6000 });

        console.log("error", error);
        yield put(addCouponFailure({ ...error?.response?.data }));
    }
}

function* getSingleCoupon({ payload }) {
    try {
        const { data } = yield call(getSingleCouponApi, payload);
        yield put(getSingleCouponsuccess(data));
    } catch (error) {
        toastErrorMessage({ error: error?.response?.data });

        console.log(error);
        yield put(getSingleCouponFailure(error?.response?.data || error));
    }
}

function* editCoupon({ payload }) {
    try {
        const { data } = yield call(editCouponApi, payload);
        yield put(editCouponSuccess(data));
        if (payload?.callBack) payload?.callBack();
    } catch (error) {
        toastErrorMessage({ error: error?.response?.data, autoClose: 6000 });

        console.log(
            JSON.parse(JSON.stringify(error?.response?.data?.errors?.[0]?.msg))
        );
        yield put(editCouponFailure(error?.response?.data || error));
    }
}

function* deleteCoupon({ payload }) {
    try {
        yield call(deleteCouponApi, payload);
        yield put(deleteCouponSuccess(payload));
    } catch (error) {
        toastErrorMessage({ error: error?.response?.data });

        console.log(error);
        yield put(deleteCouponFailure(error?.response?.data || error));
    }
}

function* activateCoupone({ payload }) {
    try {
        const { data } = yield call(activateCouponApi, payload);
        yield put(activateCouponSuccess(data));
        toastSuccessMessage({ msg: "couponStatusEdited" });
    } catch (error) {
        toastErrorMessage({ error: error?.response?.data });

        console.log(error);
        yield put(
            activateCouponFailure(error.response?.data?.message || error)
        );
    }
}

export function* watchGetCoupons() {
    yield takeEvery(GET_COUPONS, getCoupons);
}

export function* watchAddCoupon() {
    yield takeEvery(ADD_COUPON, addCoupon);
}

export function* watchGetSingleCoupon() {
    yield takeEvery(GET_SINGLE_COUPON, getSingleCoupon);
}

export function* watchEditCoupon() {
    yield takeEvery(EDIT_COUPON, editCoupon);
}

export function* watchDeleteCoupon() {
    yield takeEvery(DELETE_COUPON, deleteCoupon);
}

export function* watchActivateCoupon() {
    yield takeEvery(ACTIVATE_COUPON, activateCoupone);
}

function* couponsSaga() {
    yield all([fork(watchGetCoupons)]);
    yield all([fork(watchAddCoupon)]);
    yield all([fork(watchGetSingleCoupon)]);
    yield all([fork(watchEditCoupon)]);
    yield all([fork(watchDeleteCoupon)]);
    yield all([fork(watchActivateCoupon)]);
}

export default couponsSaga;
