import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addProductCategories } from "store/actions";
import { useNavigate } from "react-router-dom";

// import imageUpload from "../../assets/svgs/imageUpload.svg";
// import server from "api/server";
import { toast } from "react-toastify";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import SeoForm from "components/shared/SeoForm";
import { getPublicImage } from "helpers/functions";
import ImageUpload from "components/shared/ImageUpload";

const AddCategory = ({ category, handleClose = () => {} }) => {
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { error } = useSelector((state) => state.productCategories);
  useEffect(() => {
    if (error === "Validation Error")
      toast.error("تاكد من صحة البيانات (منها عدم تكرار الاسم)");
  }, [error]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const imageChange = async (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     const { data } = await server().post("/upload", formData);
  //     setSelectedImage({
  //       preview: e.target.files[0],
  //       path: getPublicImage(data.data.files[0]),
  //     });
  //   }
  // };
  // const removeSelectedImage = () => {
  //   setSelectedImage();
  // };

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) {
      data["image"] = selectedImage.path;
    }
    data["icon"] = data["image"];
    if (!data.icon?.length > 0) return toast.error("صورة التصنيف مفقودة");
    if (category?.parentCategory)
      data.parentCategory = category?.parentCategory || "";
    console.log(data);
    dispatch(addProductCategories({ data, navigate, handleClose }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف تصنيف جديد</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col lg={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة التصنيف</h5>
                      {/* <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={
                                  selectedImage &&
                                  selectedImage.preview &&
                                  URL.createObjectURL(selectedImage.preview)
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>

                        <p className="error-hint">
                          {errors.image?.type === "required" &&
                            "يرجي اختيار صورة التصنيف"}
                        </p>
                      </div> */}
                      <ImageUpload
                        className="m-1"
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group required">
                      <MultiLangWrapper
                        label="إسم التصنيف "
                        errors={errors?.name}
                      >
                        {({ lang, classes, key }) => (
                          <input
                            key={key}
                            type="text"
                            className={`form-control form-outline ${classes}`}
                            placeholder="اسم التصنيف"
                            {...register(`name[${lang}]`, { required: true })}
                          />
                        )}
                        <p className="error-hint">
                          {errors?.name && "يرجي ادخال اسم التصنيف"}
                        </p>
                      </MultiLangWrapper>
                    </div>
                  </Col>

                  <SeoForm
                    errors={errors}
                    register={register}
                    required={false}
                  />

                  <input type="hidden" {...register("image")} />
                  <Col lg={12}>
                    <div className="form-group d-inline-flex">
                      <button type="submit" className="btn btn-blue">
                        اضافة التصنيف
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
