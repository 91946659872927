/* eslint-disable no-unused-vars */
import React from "react";
import { Modal } from "react-bootstrap";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import { ReactComponent as Upload } from "assets/svgs/product/upload.svg";
import server from "api/server";
import { getPublicImage, handleImage } from "helpers/functions";
import ImageUpload from "components/shared/ImageUpload";
const ImagesModal = ({
  handleClose,
  modalType,
  selectedImage,
  setSelectedImage,
  isNew,
}) => {
  return (
    <div
      className="productModal"
      style={{
        zIndex: 9999,
        paddingLeft: "0px !important",
        paddingRight: "0px",
      }}
    >
      <Modal
        dialogClassName="productDataModal brandDataModal"
        show={modalType === "imagesModal"}
        onHide={handleClose}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Upload fill="#FC6B14" />
            تعديل صورة العلامة التجارية
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="form-body d-flex justify-content-center ">
              <div className="multiUploadWrapper">
                <ImageUpload
                  className="m-1"
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline"
            onClick={handleClose}
            type="button"
          >
            إلغاء
          </button>
          <button type="button" className="btn btn-blue" onClick={handleClose}>
            حفظ
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImagesModal;
