import {
    ADD_COUPON,
    ADD_COUPON_FAILURE,
    ADD_COUPON_SUCCESS,
    ACTIVATE_COUPON,
    ACTIVATE_COUPON_FAILURE,
    ACTIVATE_COUPON_SUCCESS,
    DELETE_COUPON,
    DELETE_COUPON_FAILURE,
    DELETE_COUPON_SUCCESS,
    EDIT_COUPON,
    EDIT_COUPON_FAILURE,
    EDIT_COUPON_SUCCESS,
    GET_COUPONS,
    GET_COUPONS_FAILURE,
    GET_COUPONS_SUCCESS,
    GET_SINGLE_COUPON,
    GET_SINGLE_COUPON_FAILURE,
    GET_SINGLE_COUPON_SUCCESS,
} from "./actionTypes";

export const getCoupons = (payload) => {
    return {
        type: GET_COUPONS,
        payload: payload,
    };
};

export const getCouponsSuccess = (coupons) => {
    return {
        type: GET_COUPONS_SUCCESS,
        payload: coupons,
    };
};

export const getCouponsFailure = (error) => {
    return {
        type: GET_COUPONS_FAILURE,
        payload: error,
    };
};

export const getSingleCoupon = (id) => {
    return {
        type: GET_SINGLE_COUPON,
        payload: id,
    };
};

export const getSingleCouponsuccess = (coupon) => {
    return {
        type: GET_SINGLE_COUPON_SUCCESS,
        payload: coupon,
    };
};

export const getSingleCouponFailure = (error) => {
    return {
        type: GET_SINGLE_COUPON_FAILURE,
        payload: error,
    };
};

export const addCoupon = (coupon) => {
    return {
        type: ADD_COUPON,
        payload: coupon,
    };
};

export const addCouponSuccess = (coupon) => {
    return {
        type: ADD_COUPON_SUCCESS,
        payload: coupon,
    };
};

export const addCouponFailure = (error) => {
    return {
        type: ADD_COUPON_FAILURE,
        payload: error,
    };
};

export const editCoupon = (coupon) => {
    return {
        type: EDIT_COUPON,
        payload: coupon,
    };
};

export const editCouponSuccess = (coupon) => {
    return {
        type: EDIT_COUPON_SUCCESS,
        payload: coupon,
    };
};

export const editCouponFailure = (error) => {
    return {
        type: EDIT_COUPON_FAILURE,
        payload: error,
    };
};

export const deleteCoupon = (id) => {
    return {
        type: DELETE_COUPON,
        payload: id,
    };
};

export const deleteCouponSuccess = (id) => {
    return {
        type: DELETE_COUPON_SUCCESS,
        payload: id,
    };
};

export const deleteCouponFailure = (error) => {
    return {
        type: DELETE_COUPON_FAILURE,
        payload: error,
    };
};

export const activateCoupon = (id) => {
    return {
        type: ACTIVATE_COUPON,
        payload: id,
    };
};

export const activateCouponSuccess = (id) => {
    return {
        type: ACTIVATE_COUPON_SUCCESS,
        payload: id,
    };
};

export const activateCouponFailure = (error) => {
    return {
        type: ACTIVATE_COUPON_FAILURE,
        payload: error,
    };
};
