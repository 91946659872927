import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  addBrand,
  deleteBrand,
  deleteEmptyBrand,
  editBrand,
} from "store/actions";
import Swal from "sweetalert2";

import SettingModal from "./SettingModal";
import ImagesModal from "./ImagesModal";

import { ReactComponent as UploadImgsIcon } from "assets/svgs/product/gallery-export.svg";
import { ReactComponent as ProductTitleIcon } from "assets/svgs/product/title.svg";

import { ReactComponent as ProductMoreIcon } from "assets/svgs/product/settings.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
import { ReactComponent as RemoveIcon } from "assets/svgs/x.svg";

import { toast } from "react-toastify";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { getId, handleImage } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";

const BrandCard = ({ brand, isCheck, handleClick }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const submitBtn = useRef(null);

  const [modalType, setModalType] = useState("");
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const [bannerImage, setBannerImage] = useState({
    preview: null,
    path: null,
  });

  const handleShow = (modalType) => {
    if (modalType === "settingModal") {
      setModalType("settingModal");
    } else {
      setModalType("imagesModal");
    }
  };

  const handleClose = () => {
    setModalType("");
  };

  const handleDeleteProduct = () => {
    Swal.fire({
      title: "هل تريد حذف العلامة!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBrand(getId(brand)));
      }
    });
  };
  // Setting Modals Data On Start
  useEffect(() => {
    reset({
      image: brand?.image || "",
      name: brand?.name || "",
      seo: {
        title: brand?.seo?.title || "",
        description: brand?.seo?.description || "",
      },
      isShown: brand?.isShown || brand?.isNew,
      showProducts: brand?.showProducts || brand?.isNew,
    });
    if (brand?.image) setSelectedImage({ path: brand?.image });
    if (brand?.banner) setBannerImage({ path: brand?.banner });
  }, [reset, brand]);

  // const imageShifterUrl = brand.image.split(process.env.REACT_APP_URL)[1];

  // Handle Submit Product Data
  const handleSubmitMainData = (data) => {

    // data.image = "uploads/" + selectedImage?.path?.split("/").pop();
    // data.banner = "uploads/" + bannerImage?.path?.split("/").pop();
    if(selectedImage?.path) {
      data.image = selectedImage?.path;
    }else {
      toast.error("برجاء اختيار صورة");
      return
    }
    data.image = selectedImage?.path;
    data.banner = bannerImage?.path;
    

    if (!brand?.isNew) {
      dispatch(
        editBrand({
          data: {
            ...data,
            // image: imageShifterUrl,
          },
          id: getId(brand),
          toast,
          callBack: handleClose,
        })
      );
    } else {
      dispatch(
        addBrand({
          data,
          id: getId(brand),
          toast,
          callBack: handleClose,
        })
      );
    }
  };
  return (
    <div className="card">
      <Form
        id={getId(brand) + "form"}
        onSubmit={handleSubmit(handleSubmitMainData)}
      >
        <div className="productCard brandCard">
          <input
            id={getId(brand)}
            type="checkbox"
            className="position-absolute"
            style={{ top: "10px", right: "10px" }}
            onChange={handleClick}
            checked={isCheck.includes(getId(brand))}
          />
          {brand?.isNew ? (
            <button
              type="button"
              onClick={() => {
                dispatch(
                  deleteEmptyBrand({
                    id: getId(brand),
                  })
                );
              }}
              className="deleteNewProduct"
            >
              <RemoveIcon fill="#fff" />
            </button>
          ) : null}
          <div className={brand?.isNew ? "newProduct p-img" : "p-img"}>
            <div className="img-holder">
              <img
                src={handleImage(selectedImage?.path) || brand?.image}
                alt="brandImage"
                className={brand?.isNew ? "newProduct" : ""}
              />
            </div>

            <button
              className="btn btn-blue"
              type="button"
              onClick={() => {
                handleShow("imagesModal");
              }}
            >
              تعيين صورة
              <UploadImgsIcon fill="#fff" />
            </button>
          </div>
          <div className="p-data">
            <div className="form-group">
              <MultiLangWrapper
                label={formatMessage({ id: "name" })}
                errors={errors?.description}
              >
                {({ lang, classes, key }) => (
                  <>
                    <ProductTitleIcon fill="#E4E4E4" />
                    <input
                      key={key}
                      type="text"
                      className={`form-control form-outline ${classes}`}
                      placeholder={formatMessage({ id: "name" })}
                      {...register(`name[${lang}]`, { required: true })}
                    />
                  </>
                )}
              </MultiLangWrapper>
                <span className="error-hint" key={getId(brand)}>
                  {errors?.name && <FormattedMessage id="required" />}
                </span>
            </div>

            <div
              className={
                brand?.isNew
                  ? "form-group has-btns disabled"
                  : "form-group has-btns"
              }
            >
              <button
                type="button"
                onClick={() => {
                  handleDeleteProduct(getId(brand));
                }}
              >
                <DeleteIcon fill="#f00" />
                حذف العلامة
              </button>
              <button
                type="button"
                onClick={() => {
                  handleShow("settingModal", getId(brand));
                }}
              >
                <ProductMoreIcon fill="#FC6B14" />
                اعدادات
              </button>
              {/* {brand?.products?.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    handleShow("settingModal", getId(brand));
                  }}
                >
                  <ProductSettingIcon fill="#FC6B14" />
                  عرض المنتجات ({" "}
                  {brand?.products?.length > 0 ? brand?.products?.length : 0} )
                </button>
              )} */}
              {/* <Dropdown>
                <Dropdown.Toggle>
                  <ProductMoreIcon fill="#FC6B14" />
                  المـزيد
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="delete"
                    onClick={() => {
                      handleDeleteProduct(getId(brand));
                    }}
                  >
                    <DeleteIcon fill="#f00" />
                    حذف العلامة
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-blue">
                {brand?.isNew ? "اضافة" : "حفظ"}
              </button>
            </div>
          </div>
        </div>
      </Form>

      <SettingModal
        brand={brand}
        handleClose={handleClose}
        modalType={modalType}
        handleSubmitMainData={handleSubmitMainData}
        bannerImage={bannerImage}
        setBannerImage={setBannerImage}
      />

      <ImagesModal
        handleClose={handleClose}
        modalType={modalType}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        isNew={brand?.isNew}
      />
    </div>
  );
};

export default BrandCard;
