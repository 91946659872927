import { getId } from "helpers/functions";
import {
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAILURE,
  ADD_BRAND,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAILURE,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE,
  GET_SINGLE_BRAND,
  GET_SINGLE_BRAND_SUCCESS,
  GET_SINGLE_BRAND_FAILURE,
  EDIT_BRAND,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_FAILURE,
  ORDER_BRAND,
  ORDER_BRAND_SUCCESS,
  ORDER_BRAND_FAILURE,
  ADD_EMPTY_BRAND,
  ADD_EMPTY_BRAND_SUCCESS,
  ADD_EMPTY_BRAND_FAILURE,
  DELETE_EMPTY_BRAND,
  DELETE_EMPTY_BRAND_SUCCESS,
  DELETE_EMPTY_BRAND_FAILURE,
  GET_BRANDS_SETTING,
  GET_BRANDS_SETTING_SUCCESS,
  GET_BRANDS_SETTING_FAILURE,
  EDIT_BRANDS_SETTING,
  EDIT_BRANDS_SETTING_SUCCESS,
  EDIT_BRANDS_SETTING_FAILURE,
  DELETE_MULTIPLE_BRANDS_SUCCESS,
  DELETE_MULTIPLE_BRANDS,
  DELETE_MULTIPLE_BRANDS_FAILURE,
} from "./actionTypes";

const initialState = {
  brands: [],
  metaData: {},
  singleBrand: {},
  settings: {},
  loading: false,
  error: "",
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRANDS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BRANDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: action.payload.brands?.sort((a, b) => a?.order - b?.order),
        metaData: action.payload.metadata,
      };
      break;
    case GET_BRANDS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ================== GET SINGLE BRAND ==================

    case GET_SINGLE_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleBrand: action.payload.brand,
      };
      break;
    case GET_SINGLE_BRAND_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleBrand: {},
      };
      break;

    // ================== ADD BRAND ==================

    case ADD_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: state.brands.map((brand) => {
          if (getId(brand) !== getId(action.payload)) return brand;
          return action.payload.brand;
        }),
      };
      break;
    case ADD_BRAND_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ================== EDIT BRAND ==================

    case EDIT_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;

    case EDIT_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: [
          ...state.brands.map((brand) => {
            if (getId(brand) !== getId(action.payload)) return brand;
            return action.payload;
          }),
        ],
      };
      break;

    case EDIT_BRAND_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // ================== ORDER BRAND ==================

    case ORDER_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ORDER_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: action?.payload?.orderedBrands?.sort(
          (a, b) => a?.order - b?.order
        ),
      };
      break;

    case ORDER_BRAND_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ================== DELETE BRAND ==================

    case DELETE_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_BRAND_SUCCESS:
      const brandsAfterDeleteing = [
        ...state?.brands?.filter((brand) => getId(brand) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        brands: brandsAfterDeleteing,
      };
      break;
    case DELETE_BRAND_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // ================== ADD EMPTY BRAND ==================

    case ADD_EMPTY_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_EMPTY_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: [action.payload, ...state.brands],
      };
      break;

    case ADD_EMPTY_BRAND_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ================== DELETE EMPTY BRAND ==================

    case DELETE_EMPTY_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_EMPTY_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: state.brands.filter(
          (brand) => getId(brand) !== getId(action.payload)
        ),
      };
      break;

    case DELETE_EMPTY_BRAND_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // ================== BRANDS SETTINGS ==================

    case GET_BRANDS_SETTING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BRANDS_SETTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: action.payload,
      };
      break;
    case GET_BRANDS_SETTING_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // ================== EDIT BRANDS SETTINGS ==================

    case EDIT_BRANDS_SETTING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_BRANDS_SETTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: action.payload,
      };
      break;
    case EDIT_BRANDS_SETTING_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // ================== DELETE MULTIPLE BRANDS ==================

    case DELETE_MULTIPLE_BRANDS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_MULTIPLE_BRANDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: state.brands.filter(
          (brand) => !action.payload?.brands.includes(brand._id)
        ),
      };
      break;
    case DELETE_MULTIPLE_BRANDS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // ================== DEFAULT ==================

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default brands;
