import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Card, Row, Col, Badge, CardHeader } from "react-bootstrap";
import Loader from "../../components/shared/Loader";
import { getSingleStore } from "store/actions";
import { useParams } from "react-router-dom";
import Styles from "./stores.module.scss";

const ShowStore = () => {
  const currentStore = useParams()?.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleStore(currentStore));
  }, [dispatch, currentStore]);

  const { locale } = useIntl();
  const { singleStore, loading } = useSelector((state) => state?.stores);

  if (loading) return <Loader />;

  const storeDetails = [
    { label: "اسم المتجر", value: singleStore?.name?.[locale] },
    {
      label: "التصنيف",
      value: singleStore?.category?.name?.[locale] || "غير محدد",
    },
    {
      label: "الوصف",
      value: singleStore?.description?.[locale] || "لا يوجد وصف",
    },
    {
      label: "الباقة",
      value: singleStore?.package?.name?.[locale] || "غير محددة",
    },
    { label: "النطاق", value: singleStore?.domain || "غير متوفر" },
  ];

  const storeImages = [
    {
      src: singleStore?.logo || "path-to-placeholder",
      label: "اللوجو",
    },
    {
      src: singleStore?.favIcon || "path-to-placeholder",
      label: "الايقونه",
    },
  ];
  return (
    <div className={`show-store-container ${Styles.singleStore}`}>
      <Card className="mb-4">
        <Card.Body>
          <Row>
            {/* Images Section */}
            {storeImages.map((img, index) => (
              <Col md={6} key={index}>
                <div className="img-container mb-3">
                  <img src={img.src} alt={img.label} className="rounded-3" />
                  <Badge bg="unset" className="px-4 py-3 img-badge">
                    {img.label}
                  </Badge>
                </div>
              </Col>
            ))}
          </Row>

          {/* Store Details Section */}
          <Row className="mt-4">
            {storeDetails.map((detail, index) => (
              <Col md={6} key={index}>
                <Card className="store-card">
                  <strong className="card-label">{detail.label}</strong>
                  {detail.label === "التصنيف" ? (
                    <Badge bg="unset" className="category-badge">
                      {detail.value}
                    </Badge>
                  ) : (
                    <p>{detail.value}</p>
                  )}
                </Card>
              </Col>
            ))}
          </Row>

          {/* Branches Section */}
          {singleStore?.branches?.length > 0 && (
            <div className="mt-4">
              <CardHeader className="branches-label mb-3 rounded-4">
                <strong className="card-label">الفروع</strong>
              </CardHeader>
              <Row>
                {singleStore.branches.map((branch, index) => (
                  <Col md={6} key={index}>
                    <Card className="store-card mb-3">
                      <Card.Body>
                        <strong className="card-label d-block">
                          فرع {index + 1}
                        </strong>
                        <p className="mb-1">
                          اسم الفرع: {branch?.name?.[locale]}
                        </p>
                        <p className="mb-1">الجوال: {branch?.phone}</p>
                        <p>
                          العنوان:{" "}
                          {branch?.address?.addressDetail || "غير متوفر"}
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ShowStore;
