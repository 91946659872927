import { useState } from "react";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";
import { useRef } from "react";

export default function MultiLangWrapper({
  label,
  langs = false,
  children,
  customClass,
  errors = false,
  tag = "div",
  extraListener = [],
  ...rest
}) {
  const wrapperRef = useRef();
  const { settings } = useSelector((state) => state?.settings);

  // useEffect(() => {
  //   if (!settings?.langauges?.length > 0) dispatch(getLanguages());
  // }, [dispatch, settings]);
  const settingsLangs =
    settings?.languages?.filter((e) => e?.language)?.length > 0
      ? settings?.languages?.filter((e) => e?.language)
      : false;
  const langsArray = langs ||
    settingsLangs || [{ language: { name: "عربي", locale: "ar", dir: "rtl" } }];

  const [focusedLang, setFocusedLang] = useState(
    langsArray?.[0]?.language?.locale
  );

  const selectOptions = langsArray?.map(
    ({ language: { name: label, locale } }) => ({
      label,
      value: locale,
    })
  );
  // extra children in the component
  const renderNonFieldChildren =
    children?.length > 0 &&
    typeof children == "object" &&
    children
      .filter((e) => typeof e != "function")
      ?.map((e, index) => ({ ...e, key: index }));
  //function children that would be converted to languages elements
  const renderMultiLangFields =
    //  useMemo(
    () => {
      let functionChildren = [];

      if (children?.length > 0 && typeof children == "object") {
        functionChildren = children.filter((e) => typeof e == "function");
      } else if (typeof children == "function") functionChildren = [children];

      return langsArray?.map(({ language: { name, locale } }) =>
        functionChildren?.map((child, index) =>
          child({
            lang: locale,
            classes: `${focusedLang === locale ? "displayed-field" : "d-none"}`,
            key: index + locale,
          })
        )
      );
    };
  // , [focusedLang, langsArray, ...extraListener]);

  // focus on the current selected lang input, to help enter data more quickly
  const handleFieldFocus = () => {
    setTimeout(() => {
      let element = wrapperRef?.current?.querySelector(
        ".MultiLangsWrapper .fields-wrapper .displayed-field"
      );
      if (element) element?.focus();
    }, 50);
  };

  const CustomTag = `${tag}`;

  return (
    <>
      <label
        ref={wrapperRef}
        className={`MultiLangsWrapper form-group border-0 pb-0 mb-0 d-block ${
          rest?.required ? "required" : ""
        }`}
      >
        <div className="label align-items-center d-flex mb-0">
          <h5>{label}</h5>
          <div className="w-auto">
            <ChooseLang
              focusedLang={focusedLang}
              selectOptions={selectOptions}
              setFocusedLang={(v) => {
                setFocusedLang(v);
                handleFieldFocus();
              }}
              errors={errors}
            />
          </div>
        </div>
        <CustomTag className={`fields-wrapper ${customClass}`}>
          {renderMultiLangFields()}
          {renderNonFieldChildren}
        </CustomTag>
      </label>
    </>
  );
}

//----------------------------------------
//
//
// langauges selection section, if more than 6 langauges selected, a drop list would appear, otherwise, it's tabs
const ChooseLang = ({ focusedLang, selectOptions, setFocusedLang, errors }) => {
  const CustomOption = (props) => {
    return (
      <components.Option
        {...props}
        className={`${props?.className} ${
          errors?.[props.data?.value] !== undefined &&
          "required position-relative"
        }`}
      />
    );
  };
  return (
    <>
      {selectOptions?.length > 6 ? (
        <Select
          className={`basic-single ${
            Object.keys(errors)?.length > 0 && "required"
          }`}
          classNamePrefix="select"
          isRtl={true}
          isSearchable={false}
          options={selectOptions}
          isMulti={false}
          value={selectOptions?.find((o) => o?.value === focusedLang)}
          // value={{ value: focusedLang, label: focusedLang }}
          onChange={({ value }) => {
            setFocusedLang(value);
          }}
          components={{ Option: CustomOption }}
        />
      ) : (
        <div className="langs-tabs">
          {selectOptions?.map(({ value, label }, index) => (
            <div
              key={index}
              className={`lang-tab ${focusedLang === value ? "active" : ""} ${
                errors?.[value] !== undefined && "required position-relative"
              }`}
              title={label}
              onClick={() => setFocusedLang(value)}
            >
              {value === "ar" ? "ع" : value}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
