import { Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";


import {
  conditionOptions,
  getTypeByName,
  isShownOptions,
} from "./VariationsTable";
import { getId } from "helpers/functions";
import { priceInputValidations } from "helpers/configs";


export default function TableInputsComponent({
  name,
  varIndex,
  text = "",
  errors,
  register,
  hasVariations,
  setValue,
  control,
  units = [],
  locale,
  // images: imagesVal,
}) {

  const variationFieldName = `variationsOptionsValues.${varIndex}.${name}`,
    requiredError =
      errors?.variationsOptionsValues?.[varIndex]?.[name]?.type === "required";
  let element = null,
    options = conditionOptions,
    registerObj = register(variationFieldName, {
      ...checkRequired(name, hasVariations),
    });
  if (name === "measurementUnit")
    options = units?.map((e) => ({
      value: getId(e),
      label: e?.name?.[locale],
    }));
  // eslint-disable-next-line no-fallthrough
  if (name === "isShown") options = isShownOptions;
  switch (name) {
    case "measurementUnit":
    case "isShown":
    case "condition":
      element = (
        <select
          key={varIndex + name}
          className={`form-control ${requiredError ? "border-danger" : ""}`}
          {...registerObj}
        >
          {options?.map(({ value, label }, index) => (
            <option value={value} key={index}>
              {label}
            </option>
          ))}
        </select>
      );
      break;
    case "name":
      setValue(variationFieldName, text);
      element = (
        <input
          key={varIndex + name}
          type="hidden"
          className={`${requiredError ? "border-danger" : ""}`}
          {...registerObj}
        />
      );
      break;
    case "discountDate":
      element = (
        <Controller
          control={control}
          name={variationFieldName}
          render={({ field: { onChange, ...rest } }) => {
            return (
              <Flatpickr
                className="form-control form-outline"
                placeholder="تاريخ الانتهاء"
                key = {varIndex + name}
                options={{
                  dateFormat: "Y-m-d",
                   monthSelectorType: "static"
                }}
                {...rest}
                onChange={([date]) => {
                  onChange(date);
                }}
                readOnly={false}
              />
            );
          }}
        />
      );
      break;
    case "price":
    case "discountPrice":
      element = (
        <input
          key={varIndex + name}
          className={`${requiredError ? "border-danger" : ""}`}
          {...priceInputValidations}
          {...registerObj}
        />
      );
      break;

    default:
      element = (
        <input
          key={varIndex + name}
          type={getTypeByName(name)}
          className={`${requiredError ? "border-danger" : ""}`}
          {...registerObj}
        />
      );
      break;
  }

  return element;
}

const checkRequired = (name, hasVariations) => {
  switch (name) {
    case "name":
    case "price":
    case "quantity":
    case "maxPerOrder":
    case "sku":
    case "MPN":
    case "GTIN":
      return { required: !!hasVariations };
    default:
      return {};
  }
};
